import Typography from '@/common/Typography'
import Button from '@/common/button/Button'
import React from 'react'
import { FaEye } from 'react-icons/fa'
import { useNavigate } from 'react-router'

import { imagesUrl } from '@/assets/images/imageUrls'
import { StartUpStructure } from '@/apis/networkApis'
import {
  useChangeConnectionStatus,
  useCreateConnection,
  useDeleteConnection,
} from '@/store/networkStore'
import { CONNECTIONS } from '@/constants/querryKeys'

interface Props {
  id: number
  img: string
  name: string
  tags: string[]
  desc: string
  onClickFollow?: (id: string) => void
  onClickView?: (item: Props) => void
}

function StartupCard({
  id,
  country,
  name,
  members,
  logo,
  industry,
  connection,
  about_us,
}: Partial<StartUpStructure>) {
  const navigate = useNavigate()

  // delete connection
  const { mutate: deleteConnection, isLoading: deleteConnectionLoading } =
    useDeleteConnection({
      invalidateKey: [CONNECTIONS],
    })

  // create connection
  const { mutate: createConnection, isLoading: createConnectionLoading } =
    useCreateConnection({
      invalidateKey: [CONNECTIONS],
    })

  //accept Connection
  const { mutateAsync: acceptConnection, isLoading: acceptConnectionLoading } =
    useChangeConnectionStatus({
      invalidateKey: [CONNECTIONS],
    })

  let btn: React.ReactElement = <></>
  switch (connection?.status) {
    case 'Pending':
      btn = (
        <>
          {connection.is_sender ? (
            <div className='flex gap-1 items-center'>
              {' '}
              <Button
                size='xs'
                color='green'
                className='px-3'
                onClick={() =>
                  acceptConnection({ id: connection.id, status: 'Accepted' })
                }
                loading={acceptConnectionLoading}
              >
                Accept
              </Button>{' '}
              <Button
                size='xs'
                color='lightred'
                className='px-3'
                onClick={() => deleteConnection({ id: connection.id })}
                loading={deleteConnectionLoading}
              >
                Decline
              </Button>{' '}
            </div>
          ) : (
            <Button
              size='sm'
              color='lightred'
              className='px-4'
              onClick={() => deleteConnection({ id: connection?.id })}
              loading={deleteConnectionLoading}
            >
              Delete
            </Button>
          )}
        </>
      )
      break
    case 'Declined':
      btn = (
        <Button
          color='plain'
          className='rounded  border border-primary !w-full'
          onClick={() => createConnection({ id: id! })}
          size='sm'
          loading={createConnectionLoading}
        >
          <span className='flex items-center justify-center gap-2'>
            <svg
              width='9'
              height='8'
              viewBox='0 0 9 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.5 0C4.71217 0 4.91566 0.0842856 5.06569 0.234315C5.21571 0.384344 5.3 0.587827 5.3 0.8V3.2H7.7C7.91217 3.2 8.11566 3.28429 8.26569 3.43431C8.41571 3.58434 8.5 3.78783 8.5 4C8.5 4.21217 8.41571 4.41566 8.26569 4.56569C8.11566 4.71571 7.91217 4.8 7.7 4.8H5.3V7.2C5.3 7.41217 5.21571 7.61566 5.06569 7.76569C4.91566 7.91571 4.71217 8 4.5 8C4.28783 8 4.08434 7.91571 3.93431 7.76569C3.78429 7.61566 3.7 7.41217 3.7 7.2V4.8H1.3C1.08783 4.8 0.884344 4.71571 0.734315 4.56569C0.584286 4.41566 0.5 4.21217 0.5 4C0.5 3.78783 0.584286 3.58434 0.734315 3.43431C0.884344 3.28429 1.08783 3.2 1.3 3.2H3.7V0.8C3.7 0.587827 3.78429 0.384344 3.93431 0.234315C4.08434 0.0842856 4.28783 0 4.5 0Z'
                fill='#272343'
              />
            </svg>
            Connect
          </span>
        </Button>
      )
      break
    case 'Accepted':
      btn = (
        <Button
          color='lightgreen'
          className='rounded  border'
          onClick={() => deleteConnection({ id: connection.id })}
          size='sm'
          loading={deleteConnectionLoading}
        >
          Unconnect
        </Button>
      )
      break

    default:
      btn = (
        <Button
          color='plain'
          className='rounded  border border-primary !w-full'
          onClick={() => createConnection({ id: id! })}
          size='sm'
          loading={createConnectionLoading}
        >
          <span className='flex items-center justify-center gap-2'>
            <svg
              width='9'
              height='8'
              viewBox='0 0 9 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.5 0C4.71217 0 4.91566 0.0842856 5.06569 0.234315C5.21571 0.384344 5.3 0.587827 5.3 0.8V3.2H7.7C7.91217 3.2 8.11566 3.28429 8.26569 3.43431C8.41571 3.58434 8.5 3.78783 8.5 4C8.5 4.21217 8.41571 4.41566 8.26569 4.56569C8.11566 4.71571 7.91217 4.8 7.7 4.8H5.3V7.2C5.3 7.41217 5.21571 7.61566 5.06569 7.76569C4.91566 7.91571 4.71217 8 4.5 8C4.28783 8 4.08434 7.91571 3.93431 7.76569C3.78429 7.61566 3.7 7.41217 3.7 7.2V4.8H1.3C1.08783 4.8 0.884344 4.71571 0.734315 4.56569C0.584286 4.41566 0.5 4.21217 0.5 4C0.5 3.78783 0.584286 3.58434 0.734315 3.43431C0.884344 3.28429 1.08783 3.2 1.3 3.2H3.7V0.8C3.7 0.587827 3.78429 0.384344 3.93431 0.234315C4.08434 0.0842856 4.28783 0 4.5 0Z'
                fill='#272343'
              />
            </svg>
            Connect
          </span>
        </Button>
      )

      break
  }

  return (
    <div className='flex p-2 flex-row h-fit gap-1 rounded-lg bg-[white] md:flex-1 md:h-[410px] md:gap-3  md:justify-between md:flex-col md:p-0'>
      <img
        src={logo || imagesUrl.blanckCompImg}
        alt='start_up_logo'
        className='w-[87px] h-[75px] rounded-[8px] object-center object-cover md:rounded-t-lg md:w-full md:h-[183px]'
      />
      <div className='flex flex-col gap-2 md:justify-between md:flex-1'>
        <div className='flex flex-col gap-2 px-5 py-2'>
          <div className='flex gap-2'>
            <small
              className={`bg-blue-300 font-bold rounded p-1 uppercase text-black-1`}
            >
              {industry}
            </small>
          </div>
          <Typography heading='xs'>{name}</Typography>

          <small>{about_us}</small>
        </div>

        <div className='flex items-center justify-between w-full  gap-2 px-5 pb-3'>
          {btn}

          <Button
            className='rounded px-6 border w-full'
            onClick={() => navigate(`/app/networks/startups/${id}`)}
            size='sm'
            color='white'
          >
            <span className='flex gap-2 items-center justify-center'>
              <FaEye /> View
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default StartupCard
