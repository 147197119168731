import { FaExternalLinkAlt } from 'react-icons/fa'

import { imagesUrl } from '@/assets/images/imageUrls'
import { StartUpStructure } from '@/apis/networkApis'
import ConnectionBtn from './ConnectionBtn'

const tagColor = ['bg-blue-300', 'bg-orange-300', 'bg-green-300']
function SingleStartupDesc({
  id,
  country,
  name,
  members,
  logo,
  industry,
  product_type,
  is_registered,
  about_us,
  website,
  mission,
  vision,
  bio_video,
  created,
  updated,
  connection,
}: Partial<StartUpStructure>) {
  return (
    <>
      <div className='bg-[white] p-4 border rounded-lg flex flex-col gap-2 items-start w-full sm:flex-row sm:justify-between sm:items-center'>
        <div className='flex gap-2 flex-col justify-start sm:flex-row'>
          <img
            src={logo || imagesUrl.blanckCompImg}
            alt='start_up_logo'
            className='h-[129px] w-[129px] object-center object-cover rounded-[8px]'
          />
          <div className='flex flex-col gap-1 px-2 sm:px-3'>
            <div className='flex flex-col items:start justify-center'>
              <h3>{name}</h3>
              <div className='flex gap-2'>
                <small
                  className={`bg-blue-300 font-bold rounded p-1 uppercase text-[0.6rem]`}
                >
                  {industry}
                </small>
              </div>
            </div>
            <div className='flex flex-col gap-3 mt-4'>
              <div className='flex flex-col gap-2'>
                <small className='font-light'>Company website</small>

                <a
                  href={website}
                  target='_blanck'
                  className='flex gap-3 text-blue-500'
                >
                  {website}
                  <FaExternalLinkAlt />
                </a>
              </div>
              <div className='flex gap-2 mt-0 sm:gap-4'>
                <ConnectionBtn
                  createConnectionObj={{
                    id: id!,
                    type: 'group',
                  }}
                  invalidateKey={['']}
                  connection={connection!}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleStartupDesc
