import React, { useState } from 'react'
import ButtonTab from './ButtonTab'

import Typography from '@/common/Typography'
import { StartUpStructure } from '@/apis/networkApis'

const tabItems = [
  {
    name: 'About',
    path: 'about',
  },
  {
    name: 'Vision',
    path: 'vision',
  },
  {
    name: 'Mission',
    path: 'mission',
  },
]

function AboutCard({ about_us, mission, vision }: Partial<StartUpStructure>) {
  const [tab, settab] = useState<string>('about')

  let view: null | JSX.Element = null

  const handleChangeTab = (view: string) => {
    settab(view)
  }

  switch (tab) {
    case 'about':
      view = (
        <div className='flex flex-col gap-3 items-start'>
          <Typography heading='3xs'> About</Typography>

          <p>{about_us}</p>
        </div>
      )
      break
    case 'vision':
      view = (
        <div className='flex flex-col gap-3 items-start'>
          <Typography heading='3xs'> Vision</Typography>

          <p>{vision}</p>
        </div>
      )
      break
    case 'mission':
      view = (
        <div className='flex flex-col gap-3 items-start'>
          <Typography heading='3xs'> Mission</Typography>

          <p>{mission}</p>
        </div>
      )
      break

    default:
      break
  }

  return (
    <div className='bg-[white] p-4  rounded-lg flex flex-col gap-4 items-start w-full'>
      <ButtonTab
        containerClass='border border-gray-100 px-2'
        activeProperty={tab}
        items={tabItems}
        onClick={handleChangeTab}
        activeClass='!bg-[#BAE8E8]'
      />
      {view}
    </div>
  )
}

export default AboutCard
