import classNames from 'classnames'
import React, {
  ChangeEvent,
  ReactNode,
  InputHTMLAttributes,
  FormEvent,
} from 'react'
import LazySpinner from './spinners/LazySpinner'
import CircularProgress from './spinners/CircularProgress'
import Typography from './Typography'
import clsx from 'clsx'

interface Props
  extends InputHTMLAttributes<
    HTMLInputElement | HTMLFormElement | HTMLSpanElement
  > {
  startIcon?: ReactNode
  endIcon?: ReactNode
  type?: string
  className?: string
  fieldType?: 'input' | 'textarea'
  rows?: string
  isLoading?: boolean
  error?: string
  title?: string
  helperText?: string
  containerClass?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

function TextInput({
  onChange,
  startIcon,
  endIcon,
  type = 'text',
  fieldType = 'input',
  className = '',
  rows = '',
  isLoading = false,
  title,
  error,
  helperText,
  containerClass,
  ...rest
}: Props) {
  return (
    <div className={clsx('w-auto flex flex-col gap-2', containerClass)}>
      {title && (
        <Typography
          htmlFor={rest?.name || ''}
          as='label'
          label='sm'
          className='text-sifuse-shades-900 text-sm leading-4 font-medium'
        >
          {title}
        </Typography>
      )}

      <div className='relative w-auto'>
        {isLoading && (
          <span className='absolute left-3 text-gray-400 top-1/2 -translate-y-1/2'>
            <LazySpinner show={isLoading} delay={0}>
              <CircularProgress size='18px' color={'black'} />
            </LazySpinner>
          </span>
        )}
        {!isLoading && startIcon && (
          <span
            className={clsx(
              'absolute left-3 text-gray-400 top-1/2 -translate-y-1/2',
              {
                '!top-1/4': fieldType === 'textarea',
              }
            )}
          >
            {startIcon}
          </span>
        )}
        {fieldType === 'textarea' ? (
          <textarea
            className={classNames(
              `${
                className ? className : ''
              }  rounded border-gray-100 focus:outline-none focus:border-gray-100 placeholder-gray-400 `,
              { 'pl-9': !!startIcon }
            )}
            onChange={onChange}
            rows={rows}
            {...(rest as any)}
          />
        ) : (
          <input
            type={type}
            className={classNames(
              `${
                className ? className : ''
              }  rounded border-gray-100 focus:outline-none focus:border-gray-100 placeholder-gray-400 `,
              { 'pl-9': !!startIcon }
            )}
            onChange={onChange}
            {...rest}
          />
        )}
        {endIcon && (
          <span
            className={clsx(
              'absolute right-3 text-gray-400 top-1/2 -translate-y-1/2 cursor-pointer',
              {
                '!top-1/4': fieldType === 'textarea',
              }
            )}
          >
            {endIcon}
          </span>
        )}
      </div>
      {helperText && <Typography paragraph='xs'>{helperText}</Typography>}
      {error && (
        <small className='text-[var(--mainred)] font-medium text-[11px]'>
          {error}
        </small>
      )}
    </div>
  )
}

export default TextInput
