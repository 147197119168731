import React from 'react'

import TextInput from '@/common/TextInput'

import { useLocation, useNavigate } from 'react-router-dom'

import ButtonTab from './components/ButtonTab'
import StartupCard from './components/StartupCard'

import { InfiniteScroll } from './components/utils/types'
import Typography from '@/common/Typography'
import LoadingState from './components/LoadingState'

import { useGetStartups, useGetUsers } from '@/store/networkStore'
import Title from '@/common/Title'
import { useBreadCrumbs } from '@/layouts/app/components/header'
import { FaX } from 'react-icons/fa6'
import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'

import { InvestorStructure, StartUpStructure } from '@/apis/networkApis'
import DropdownButton from '@/common/DropdownButton'
import PeopleCard from './components/PeopleCard'

const StartupsTab = ({ search }: { search: string }) => {
  //get startups
  const {
    data: startUps,
    isLoading: startUpsLoading,
    isError: isStartUpsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetStartups({ query: search })

  const startupsData = (startUps as unknown as InfiniteScroll)?.pages

  return (
    <>
      {
        //if first load

        <LoadingState
          condition={
            startUpsLoading
              ? 'isLoading'
              : startupsData?.length &&
                !startupsData[0].data.data.results.length
              ? 'isEmpty'
              : isStartUpsError
              ? 'isError'
              : ''
          }
          isEmptyDisplay={
            <>
              <Typography heading='3xs'>No Business data</Typography>
            </>
          }
        />
      }
      {search && startupsData?.length && startupsData[0].data.data.count ? (
        <div className='flex gap-1'>
          <Typography heading='3xs'>Search Results</Typography>{' '}
          <small className='font-extralight'>{`(${
            startupsData?.length && startupsData[0].data.data.count
          })`}</small>
        </div>
      ) : null}
      <InfiniteScrollContainer
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      >
        <div className=' p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-6 sm:gap-3'>
          {startupsData?.map((returnedData) =>
            returnedData?.data?.data?.results?.map(
              (startup: StartUpStructure) => (
                <StartupCard key={startup.id} {...startup} />
              )
            )
          )}
        </div>
      </InfiniteScrollContainer>
    </>
  )
}
const PeopleTab = ({ search }: { search: string }) => {
  //get users
  const {
    data: investors,
    isLoading: investorsLoading,
    isError: isinvestorsError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetUsers({ query: search, 'user type': 'Startup' })

  const investorsData = (investors as unknown as InfiniteScroll)?.pages

  return (
    <div className='mt-4'>
      {/* {
        //if first load
        <LoadingState
          condition={
            investorsLoading
              ? 'isLoading'
              : investorsData?.length &&
                !investorsData[0].data?.data?.results?.length
              ? 'isEmpty'
              : isinvestorsError
              ? 'isError'
              : ''
          }
          isEmptyDisplay={
            <>
              <Typography heading='3xs'>No User Data</Typography>
            </>
          }
        />
      } */}
      <PeopleCard
        avatar=''
        first_name='Jon'
        last_name='Doe'
        user_type='CEO'
        address='Ademola Adetokunbo, VI, Lagos'
        num_connections={5}
        connection={null}
        id={2}
      />

      <InfiniteScrollContainer
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      >
        <div className=' p-2 grid grid-cols-1 sm:p-4 sm:grid-cols-3 lg:grid-cols-4 gap-4 mt-6 sm:gap-3'>
          {investorsData?.map((data) =>
            data?.data?.data?.results?.map((investor: InvestorStructure) => (
              <PeopleCard key={investor.id} {...investor} />
            ))
          )}
        </div>
      </InfiniteScrollContainer>
    </div>
  )
}

type SubTabType = 'businessess' | 'people'
const subTabs = [
  {
    name: 'Businessess',
    path: 'businessess',
  },
  {
    name: 'People',
    path: 'people',
  },
]

const filterBy = [
  {
    name: 'None',
    value: '',
  },

  {
    name: 'Syndicates',
    value: 'Syndicate',
  },
  {
    name: 'Network',
    value: 'Network',
  },
  {
    name: 'Individual',
    value: 'Individual',
  },
]
const BreadCrumbMap = new Map()
BreadCrumbMap.set('/app/startups', 'Businesses')

function Startups() {
  useBreadCrumbs(BreadCrumbMap)
  const navigate = useNavigate()
  const location = useLocation()

  const [search, setsearch] = React.useState<string>('')
  const [subTab, setsubTab] = React.useState<SubTabType>('businessess')
  const [filter, setfilter] = React.useState('')

  const handleTabclick = (path: string) => {
    navigate(path)
  }
  const handleSubTabclick = (path: string) => {
    setsubTab(path as SubTabType)
  }
  let Tab = <></>
  switch (subTab) {
    case 'businessess':
      Tab = <StartupsTab search={search} />

      break
    case 'people':
      Tab = <PeopleTab search={search} />

      break

    default:
      break
  }

  const onChangeFilter = (value: any) => {
    setfilter(value)
  }
  return (
    <div className='w-full gap-2 flex flex-col'>
      <Title>Businesses</Title>
      <div className='flex flex-col justify-between items-start lg:flex-row lg:items-center width-full'>
        <ButtonTab
          activeProperty={location.pathname}
          onClick={handleTabclick}
        />

        <div className='flex items-center gap-1 lg:gap-2 w-full md:w-fit'>
          <TextInput
            placeholder={`Search ${
              subTab === 'businessess' ? 'Businessess' : 'Members'
            }`}
            name='search'
            value={search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setsearch(e.target.value)
            }
            endIcon={
              !!search && (
                <FaX className='text-[0.7rem]' onClick={() => setsearch('')} />
              )
            }
            startIcon={
              <svg
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M13.5007 7.66634C13.5007 10.888 10.889 13.4997 7.66732 13.4997C4.44566 13.4997 1.83398 10.888 1.83398 7.66634C1.83398 4.44468 4.44566 1.83301 7.66732 1.83301C10.889 1.83301 13.5007 4.44468 13.5007 7.66634Z'
                  fill='#6D6D77'
                  stroke='#6D6D77'
                />
                <path
                  d='M14.1997 14.6664C14.0797 14.6664 13.9597 14.6197 13.873 14.533L12.633 13.293C12.453 13.113 12.453 12.8197 12.633 12.633C12.813 12.453 13.1064 12.453 13.293 12.633L14.533 13.873C14.713 14.053 14.713 14.3464 14.533 14.533C14.4397 14.6197 14.3197 14.6664 14.1997 14.6664Z'
                  fill='#6D6D77'
                />
              </svg>
            }
            className='w-full !rounded-md border border-gray-200 md:w-[200px] h-[48px] lg:w-[322px]'
          />

          <DropdownButton
            title={'Filter by'}
            items={filterBy}
            btnClass='py-1 text-sm !bg-white !border h-[48px] hover:!bg-white !font-normal !w-[140px] md:!w-fit'
            onClick={onChangeFilter}
            changeTitleOnSelect
            trayClass='!w-auto min-w-[150px] !rounded-md !left-[-15px]'
            itemClass='!border-transparent'
            showSelected={true}
          />
        </div>
      </div>
      <ButtonTab
        activeProperty={subTab}
        onClick={handleSubTabclick}
        items={subTabs}
        activeClass='!bg-transparent !border-t-0 !border-l-0 !border-r-0 !border-b-1 !px-5 !rounded-none'
        containerClass='!w-full !pb-0 !mt-0 !mb-0'
      />
      {Tab}
    </div>
  )
}

export default Startups
