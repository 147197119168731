import React, { useRef, useState } from 'react'

import { useParams } from 'react-router'
import SingleStartupDesc from './components/SingleStartupDesc'
import AboutCard from './components/AboutCard'
import TeamInfo from './components/TeamInfo'
import classNames from 'classnames'
import DropdownButton from '@/common/DropdownButton'
import Post from './components/Post'
import StartupHighlights from './components/StartupHighlights'
import FundingInfo from './components/FundingInfo'
import StartupAssessment from './components/StartupAssessment'
import { ReactComponent as PlayIcon } from '@/assets/network/play.svg'
import { ReactComponent as PauseIcon } from '@/assets/network/pause.svg'

import LoadingState from './components/LoadingState'
import Typography from '@/common/Typography'

import { InfiniteScroll } from './components/utils/types'
import PostSkeletonLoader from './components/PostSkeletonLoader'
import { useGetPosts, useGetStartup } from '@/store/networkStore'
import { useBreadCrumbs } from '@/layouts/app/components/header'
import Title from '@/common/Title'
import InfiniteScrollContainer from '@/common/InfiniteScrollContainer'
import { StartUpStructure, TimelineResultsStructure } from '@/apis/networkApis'

const dropdownItems = [
  {
    name: 'All',
    value: 0,
  },
  {
    name: 'Older Than 6months',
    value: 6,
  },
  {
    name: 'Older Than 3months',
    value: 1,
  },
  {
    name: 'Older Than 1month',
    value: 1,
  },
]
interface VideoProps {
  videoUrl: string
  onLoadedData: () => void
}

function Video({ videoUrl, onLoadedData }: VideoProps) {
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [isHovering, setisHovering] = React.useState(false)
  const [isPlayed, setisPlayed] = React.useState(false)
  const videoRef = React.useRef<HTMLVideoElement | null>(null)

  const togglePlay = () => {
    if (isPlaying) {
      videoRef?.current?.pause()
    } else {
      videoRef?.current?.play()
    }
    setIsPlaying(!isPlaying)
  }

  const handleOnLoad = (e: any) => {
    setisPlayed(true)
  }
  const handleOnEnded = (e: any) => {
    setIsPlaying(false)
    setisPlayed(false)
  }

  return (
    <span
      className={classNames('relative w-full')}
      onMouseEnter={() => setisHovering(true)}
      onMouseLeave={() => setisHovering(false)}
    >
      <video
        ref={videoRef}
        src={videoUrl}
        className={'rounded-lg w-full h-[344.95px] object-cover'}
        onEnded={handleOnEnded}
        onPlay={(e) => handleOnLoad(e)}
        onLoadedData={onLoadedData}
      />
      {!isPlayed ? (
        <span
          onClick={togglePlay}
          className='absolute left-0 top-0 right-0 bottom-0 m-auto  cursor-pointer text-gray-300 w-[50px] h-[50px]'
        >
          <PlayIcon />
        </span>
      ) : null}
      {isPlayed && isHovering ? (
        !isPlaying ? (
          <span
            onClick={togglePlay}
            className='absolute left-0 top-0 right-0 bottom-0 m-auto  cursor-pointer text-gray-300 w-[50px] h-[50px]'
          >
            <PlayIcon />
          </span>
        ) : (
          <span
            onClick={togglePlay}
            className='absolute left-0 top-0 right-0 bottom-0 m-auto  cursor-pointer text-gray-300 w-[50px] h-[50px]'
          >
            <PauseIcon />
          </span>
        )
      ) : null}
    </span>
  )
}

function SingleStartup() {
  const { id: pgId } = useParams()
  const startupId = Number(pgId)

  const BreadCrumbMap = new Map()
  BreadCrumbMap.set('/app/networks/startups', 'Businesses')
  BreadCrumbMap.set('excludeCount', 2)

  const [isVideoLoading, setisVideoLoading] = useState<boolean>(true)
  const [filter, setfilter] = React.useState<number>(0)

  const onFilter = (value: number | any) => {
    setfilter(value)
  }

  //get startup
  const {
    data: startUp,
    isLoading: startUpLoading,
    isError: isStartUpError,
  } = useGetStartup({ id: startupId })

  //get activities
  const {
    data: userPosts,
    isLoading: postsLoading,
    isError: isPostError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPosts({
    startup_id: startupId,
    period: filter,
  })

  const posts = (userPosts as unknown as InfiniteScroll)?.pages
  const startupData = startUp as unknown as StartUpStructure

  const isPostEmpty = posts?.[0].data?.data?.results?.length < 1
  if (Object.keys(startupData || {})?.length) {
    BreadCrumbMap.set(
      `/app/networks/startups/${startupId}`,
      `${startupData.name}`
    )
  }

  useBreadCrumbs(BreadCrumbMap)

  return (
    <div className='flex flex-col items-start  p-6 gap-4 sm:flex-row '>
      <Title>{`Business - ${startupData?.name || ''}`}</Title>
      <div className='flex flex-col w-full gap-5 sm:w-[70%]'>
        {' '}
        <SingleStartupDesc {...startupData} />
        <AboutCard {...startupData} />
        <FundingInfo
          data={startupData?.funding! || []}
          isLoading={startUpLoading}
          isError={isStartUpError}
        />
        {startupData?.bio_video ? (
          <div
            className={classNames(
              'rounded w-full flex items-center justify-center h-[344.95px] relative',
              {
                'bg-gray-700': isVideoLoading,
              }
            )}
          >
            {isVideoLoading && (
              <h5 className='text-white absolute'>Loading...</h5>
            )}
            <Video
              videoUrl={startupData?.bio_video}
              onLoadedData={() => setisVideoLoading(false)}
            />
          </div>
        ) : null}
        <StartupAssessment />
        {startupData?.members.length ? (
          <TeamInfo data={startupData?.members} />
        ) : null}
        <div className='flex flex-col min-h-[60vh] items-start justify-start rounded bg-[white] border gap-3 w-full p-4'>
          <div className='flex items-center justify-between w-full '>
            <Typography heading='3xs'>Activity</Typography>

            <DropdownButton
              title='All'
              items={dropdownItems}
              btnClass='py-2 border text-xs font-light !min-w-[150px]'
              onClick={onFilter}
              changeTitleOnSelect
            />
          </div>
          {
            //if first load

            <LoadingState
              skeletonLoader={<PostSkeletonLoader />}
              condition={
                postsLoading
                  ? 'isLoading'
                  : isPostEmpty
                  ? 'isEmpty'
                  : isPostError
                  ? 'isError'
                  : ''
              }
              isEmptyDisplay={
                <>
                  <Typography heading='3xs'>No post on timeline</Typography>
                </>
              }
            />
          }
          <InfiniteScrollContainer
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetchingNextPage={isFetchingNextPage}
            containerClass='w-full'
          >
            <main className='flex flex-col gap-2 w-full'>
              {posts?.map((page) =>
                page?.data.data?.results?.map(
                  (post: TimelineResultsStructure) => (
                    <Post
                      key={post.id}
                      id={post.id}
                      name={post?.user?.name}
                      userId={post.user.id}
                      title={post?.user?.about}
                      content={post.content}
                      src={post?.user?.avatar || ''}
                      postImgs={post.media}
                      date={post.created}
                      like={post.like}
                      connection={post?.user?.connection}
                      num_likes={post.num_likes}
                      num_comments={post.num_comments}
                    />
                  )
                )
              )}
            </main>
          </InfiniteScrollContainer>
        </div>
      </div>

      <div className='flex flex-col gap-5 sm:w-[30%]'>
        <StartupHighlights />
      </div>
    </div>
  )
}

export default SingleStartup
