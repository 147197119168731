import BounceLoader from 'react-spinners/BounceLoader'
import { FC, ReactNode, Suspense, useEffect } from 'react'
import AppErrorBoundary from '@/hoc/AppErrorBoundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import AuthContextProvider from './contexts/AuthContextProvider'
import 'react-toastify/dist/ReactToastify.css'
import useNetworkStatus from './hooks/useNetworkStatus'
import { ToastNotify } from './common/toastManager'
import { usePrevious } from './hooks/usePrevious'
const queryClient = new QueryClient()

const CombineProviders: FC<{ children: ReactNode }> = ({ children }) => {
  const isOnline = useNetworkStatus()
  const prevOnline = usePrevious(isOnline)
  useEffect(() => {
    if (prevOnline === undefined || prevOnline === isOnline) return
    ToastNotify(isOnline ? 'success' : 'error', {
      message: isOnline ? 'Network Back Online' : 'Network Not Online',
      showIcon: false,
      timeout: 3000,
    })
  }, [isOnline, prevOnline])
  return (
    <AppErrorBoundary>
      <Suspense
        fallback={
          <div className='w-screen h-screen flex justify-center items-center bg-white'>
            <BounceLoader color='var(--mainprimary)' loading size={75} />
          </div>
        }
      >
        <AuthContextProvider>
          <QueryClientProvider client={queryClient}>
            {children}
          </QueryClientProvider>
        </AuthContextProvider>
      </Suspense>
    </AppErrorBoundary>
  )
}

export default CombineProviders
