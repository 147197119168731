import { FundStructure } from '@/apis/authApis'
import { bool, object, string } from 'yup'

const format = /^[-@*_\.\w]*$/
const passwordFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/

export const personalInfoSchema = (requirePassword = true) =>
  object().shape({
    first_name: string()
      .required('First name is a required field')
      .min(2, 'Input a valid first name'),
    last_name: string()
      .required('Last name is a required field')
      .min(2, 'Input a valid last name'),
    email: string().email().required(),
    // about: string().required().min(1),
    phone_number: string()
      .trim()
      .min(12)
      .max(18)
      .required('Phone number is a required field')
      .matches(/^\+?[01-9\s]+$/, 'Phone number is not valid'),
    gender: string().notRequired().oneOf(['Male', 'Female']),
    password: requirePassword
      ? string()
          .required('Password is required')
          .matches(
            passwordFormat,
            'Password must have 8 or more characters, at least one uppercase letter, and one number.'
          )
          .test(
            'special-chars',
            'Password cannot contain special characters other than _ @ . -',
            function (value) {
              return format.test(value as unknown as string)
            }
          )
          .strict(true)
      : string().notRequired(),
    confirm_password: requirePassword
      ? string()
          .min(5)
          .required('Enter Password that matches')
          .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value
          })
      : string().notRequired(),
  })

export const startupSchema = object().shape({
  name: string().required(),
  country: string().required().trim().min(1),
  industry: string().required().trim().min(1),
  website: string().url().notRequired(),
  about_us: string().required().min(5),
  is_registered: bool().default(false).required(),
})

export const fundSchema = object().shape({
  name: string().required(),
  website: string().url().required(),
  structure: string().oneOf(Object.keys(FundStructure)).required(),
  physical_address: string().required(),
  email: string().email().required(),
  phone_number: string()
    .trim()
    .min(11)
    .max(15)
    .required('Phone number is a required field')
    .matches(/^\+?[01-9\s]+$/, 'Phone number is not valid'),
})
